.error-message {
  position: fixed;
  width: 100%;
  text-align: center;
  background-color: #ff0033;
  z-index: 3;
}

.error-message__text {
  padding: 1.67rem 3rem;
  color: #fff;
  font-size: 1.4rem;
  font-family: 'Roboto', sans-serif;
}

.error-message__close-button {
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  font-size: 2rem;
  color: #fff;
  cursor: pointer;
}

@media (max-width: 767px) {
  .error-message {
    top: 0;
  }
}
