.banana-multiswitcher__wrapper {
  margin: 2rem 0;
}

.banana-multiswitcher__element {
  font-size: 1.2rem;
  margin-bottom: 0.8rem;
}

.banana-multiswitcher__title {
  font-size: 1.25rem;
  text-transform: uppercase;
  font-weight: 700;
  color: #757575;
  margin-bottom: 1rem;
}
