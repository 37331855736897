.toolTipWrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  position: fixed;
  bottom: 2rem;
  right: 2rem; }

.toolTipWrapper .tooltip {
  flex: 1;
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; }

.toolTipWrapper .tooltip .tooltiptext {
  visibility: hidden;
  /* width: 120px; */
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  width: max-content;
  /* Position the tooltip */
  position: absolute;
  /* z-index: 1; */ }

.toolTipWrapper .tooltip:hover .tooltiptext {
  visibility: visible; }

.toolTipWrapper .tooltip .tooltiptext.top {
  top: -93px;
  right: -150px; }

.toolTipWrapper .tooltip .tooltiptext.right {
  top: -45px;
  left: 155px; }

.toolTipWrapper .tooltip .tooltiptext.bottom {
  top: -0px;
  right: -150px; }

.toolTipWrapper .tooltip .tooltiptext.left {
  top: -45px;
  right: -90px; }
