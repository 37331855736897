.banana-tag {
  display: inline-block;
  padding: 0.3rem 0.8rem;
  border-radius: 2rem;
  background-color: #dedede;
  margin-bottom: 0.4rem;
}

.banana-tag__button {
  color: #dedede;
  font-size: 1.2rem;
  background-color: rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 50%;
  padding: 0.1rem 0.4rem 0.2rem;
  margin-left: 1.5rem;
  cursor: pointer;
}
