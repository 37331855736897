.battere-header {
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #8bc34a;
  z-index: 3;
  transition: transform 0.2s linear;
}

.battere-header--opened {
  transform: translate(17rem, 0px);
}

.battere-nav {
  flex: 1;
}

.battere-header__nav-button {
  color: #fff;
  font-size: 1.8rem;
  padding: 1.4rem 1.07rem 1.4rem 1.4rem;
  background-color: inherit;
  border: none;
  cursor: pointer;
}

.battere-nav__link {
  display: inline-block;
}

.battere-nav__link--active {
  -webkit-box-shadow: 0px 0px 0px 1px white;
  -moz-box-shadow: 0px 0px 0px 1px white;
  box-shadow: 0px 0px 0px 1px white;
}

.battere-nav__link__button {
  padding: 1.4rem 0.7rem;
  font-size: 1.4rem;
  color: #fff;
  font-family: 'Helvetica', 'Arial', sans-serif;
  cursor: pointer;
  background-color: inherit;
  border: none;
}

.battere-header__logo {
  font-size: 1.4rem;
  color: #fff;
  font-family: 'Helvetica', 'Arial', sans-serif;
  padding: 1.4rem;
  transition: transform 0.2s linear;
  cursor: pointer;
  background-color: inherit;
  border: none;
}

.battere-header--opened .battere-header__logo {
  transform: translate(-17rem, 0);
}

@media (max-width: 767px) {
  .battere-nav {
    margin-right: 2%;
  }
  .battere-header {
    position: relative;
  }
  .battere-nav__list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    align-items: center;
    margin-bottom: 10px;
  }
  .battere-nav__link {
    display: inline-block;
    flex: 1;
    text-align: center;
  }
  .battere-header__logo {
    display: none;
  }
}
