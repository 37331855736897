.box-manipulator__filter {
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  align-items: baseline;
}

.box-manipulator__checkbox {
  display: inline-block;
  margin-top: 0.5rem;
  margin-right: 1rem;
}

.box-manipulator__checkbox--not-visible {
  display: none;
}

.box-manipulator__radiobox {
  display: flex;
  margin-left: 1rem;
}

.box-manipulator__radiobox > * {
  font-size: 1.2rem;
  margin-right: 0.5rem;
}

.box-manipulator__select_all {
  margin-top: 1rem;
}

.box-manipulator__select_all > .banana-button {
  font-size: 1rem;
  padding: 0.2rem 0.5rem;
}
