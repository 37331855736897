.banana-checkbox-switch {
  line-height: 3em;
}

.banana-checkbox-switch span {
  margin-right: 10px;
}

.banana-checkbox-switch * {
  vertical-align: middle;
}

.banana-checkbox {
  cursor: pointer;
}

.banana-checkbox input {
  display: none;
}

.banana-checkbox__mark {
  color: #8bc34a;
  width: 0.8rem;
  padding-top: 0.13rem;
  margin-right: 0.3rem;
}
