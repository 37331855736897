.box-batteries {
  position: relative;
}

.box-batteries__box-list {
  height: 8rem;
  overflow: auto;
}

.box-batteries__title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.box-batteries__input {
  margin-bottom: 2rem;
  width: 30rem;
}
