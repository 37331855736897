@charset "UTF-8";
.meta-block {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.meta-block *:first-child {
  margin-left: 0;
}

.meta-block *:last-child {
  margin-right: 0;
}

.block {
  position: relative;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  margin-bottom: 2rem;
}

.block--padded {
  padding: 1.5rem;
}

.block--6 {
  width: 50%;
  margin: 0 1%;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 45%;
}

.color-info {
  background-color: #ccc;
}

.title {
  margin-bottom: 1rem;
}

.title--small {
  font-size: 1.2rem;
}

.title--medium {
  font-size: 1.4rem;
}

.title--giant {
  font-size: 5rem;
}

.title--success {
  color: #8bc34a;
}

.title--center {
  text-align: center;
}

.text {
  margin-bottom: 0.3rem;
}

.text--medium  {
  font-size: 1.1rem;
}

.flex-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.container {
  margin-bottom: 0.5rem;
}

.container-icon {
  padding: 0 1rem 0 0;
}

.battere-content {
  padding: 8rem 2rem 2rem 2rem;
}

.element--is-loading:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(../images/loading-spinner.gif);
  background-size: 10%;
}

.element--is-loading-nb {
  background: #ccc url(../images/loading-spinner.gif) no-repeat center center;
  background-size: 30px;
}

.element--is-loading * {
  pointer-events: none;
}

figure {
  border: thin #c0c0c0 solid;
  display: flex;
  flex-flow: column;
  padding: 5px;
  margin: auto;
}

figcaption {
  background-color: #222;
  color: #fff;
  font: italic smaller sans-serif;
  padding: 3px;
  text-align: center;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

@media (max-width: 767px) {
  .meta-block {
    flex-wrap: wrap;
  }
  .block--6 {
    width: 100%;
    margin-top: 10px;
    margin-left: 0;
    margin-right: 0;
  }
  .battere-content {
    padding: 2rem 2rem 2rem 2rem;
  }
}
