.all-rentals-content {
  display: flex;
}

.all-rentals-content__rentals {
  width: 73%;
  margin-right: 2%;
}

.all-rentals-content__filter {
  width: 25%;
  background-color: #ececec;
  padding: 1rem;
  box-sizing: border-box;
}

.all-rentals__title {
  font-size: 1.25rem;
  text-transform: uppercase;
  font-weight: 700;
  color: #757575;
  margin-bottom: 1rem;
}

@media (max-width: 767px) {
  .all-rentals-content__filter {
    width: 100%;
  }
  .all-rentals-content__rentals {
    width: 100%;
    margin-right: 0;
  }
  .all-rentals-content {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }
}
