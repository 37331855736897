.login-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #71bf43;
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s linear;
}

.login-container--rendered {
  transform: scale(1);
  opacity: 1;
}
