.all-rentals__checkbox-cont {
  margin-bottom: 2rem;
}

.all-rentals__checkbox {
  margin-bottom: 0.4rem;
  font-size: 1.2rem;
}

.all-rentals__checkbox .banana-checkbox__mark {
  margin-right: 0.5rem;
}
