.banana-button {
  color: #fff;
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  background: #8bc34a;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border: 0;
  border-radius: 0.2rem;
  cursor: pointer; }

.banana-button:disabled {
  background-color: rgba(0, 0, 0, 0.14); }

.csv-button {
  color: #5e5e5e;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  background: #e6e6e6; }
