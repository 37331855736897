.banana-paginator {
  text-align: center;
  margin-bottom: 1rem;
}

.banana-paginator__wrapper {
  display: inline-block;
  font-size: 0;
}

.banana-paginator__element {
  display: inline-block;
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
}

.banana-paginator__element__button {
  background-color: inherit;
  border: none;
  font-size: 1rem;
  color: #000;
  padding: 1rem 0;
  width: 3rem;
  cursor: pointer;
}

.banana-paginator__element:hover {
  background-color: #dddddd;
}

.banana-paginator__element--is-active,
.banana-paginator__element--is-active:hover {
  background-color: #8bc34a;
  color: white;
}

.banana-paginator__element--is-active .banana-paginator__element__button {
  color: #fff;
}
