.obscurer {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 2;
  visibility: hidden;
}

.obscurer--visible {
  visibility: visible;
}

.route-menu {
  position: fixed;
  width: 17rem;
  height: 100%;
  transform: translate(-17rem, 0);
  background-color: #fff;
  overflow: visible;
  overflow-y: auto;
  transition: transform 0.2s linear;
  z-index: 3;
}

.route-menu__link {
  display: block;
  width: 100%;
  padding: 1.2rem 1rem;
  color: #757575;
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
  text-decoration: none;
  border: 0;
  box-sizing: border-box;
  text-align: left;
  background-color: inherit;
  cursor: pointer;
}

.route-menu__link:hover {
  background-color: #e3e3e3;
}

.route-menu__link--banana {
  padding-top: 1.9rem;
  padding-bottom: 1.9rem;
  cursor: default;
}

.route-menu__link--banana:hover {
  background-color: #fff;
}

.route-menu__link:visited {
  text-decoration: none;
}

.route-menu--is-opened {
  transform: translate(0, 0);
}

@media (max-width: 767px) {
  .obscurer {
    top: 0;
  }
  .route-menu {
    top: 0;
  }
}
