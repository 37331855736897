.banana-radio-button {
  display: flex;
  cursor: pointer;
}

.banana-radio-button__mark {
  color: #8bc34a;
  margin-right: 0.3rem;
}

.banana-radio-button input {
  display: none;
}
