.box-details {
  font-family: 'Rubik', verdana, arial, helvetica, sans-serif;
  padding: 1.5rem;
  margin-bottom: 1rem;
  position: relative;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2); }

.box-details__title {
  position: relative;
  font-size: 2rem;
  margin-bottom: 2rem; }

.box-details__id-editor {
  width: 30rem;
  margin-bottom: 2rem; }

.box-details__logista {
  width: 30rem;
  margin-bottom: 2rem; }

.box-details__logista {
  width: 30rem;
  margin-bottom: 2rem;
}

.box-details__expiration-date {
  width: 28rem;
  position: relative;
  margin-bottom: 1.5rem; }

.box-details__expiration-date__title {
  margin-bottom: 1.5rem; }

.box-details__expiration-date-editor {
  margin-top: 1.4rem;
  margin-bottom: 2rem; }

.box-details__expiration-date__content {
  font-size: 1.5rem; }

.box-details__cables__title {
  font-size: 1.5rem;
  padding-right: 3rem;
  margin-bottom: 1rem; }

.box-details__cable {
  display: table;
  position: relative;
  margin-bottom: 2rem; }

.box-details__cable__row {
  display: table-row; }

.box-details__cable__cell {
  display: table-cell;
  vertical-align: middle;
  padding: 0.4rem 0; }

.box-details__cable__cell--title {
  font-size: 1.2rem;
  padding-right: 1rem; }

.box-details__cable__edit {
  display: table-cell; }

.box-details__cable__info {
  display: table-cell; }

.box-details__edit-button {
  display: inline-block;
  margin-left: 0.5rem; }

.box-details__delete-box {
  position: absolute;
  right: 0;
  border: none;
  background-color: inherit;
  font-size: inherit;
  cursor: pointer;
}

.box-details__logista__title {
  font-size: 1.5rem;
  padding-right: 3rem;
  margin-bottom: 1rem;
}

.box-details__logista {
  padding-bottom: 10px;
}

.logisticsCode {
  margin-left: 30px;
  max-width: 300px;
  flex: 1;

  .logisticsCode__top-text {
    font-size: 1.2rem;
    display: none;
  }
}
