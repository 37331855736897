.notification {
  width: 40rem;
  position: fixed;
  bottom: 0;
  left: 50%;
  padding: 1rem 2rem;
  font-size: 1.4rem;
  color: #fff;
  text-align: center;
  background-color: #8bc34a;
  transform: translate(-50%, 0);
  z-index: 4;
}

.notification__close-button {
  position: absolute;
  right: 0.5rem;
  top: 0.1rem;
  color: #fff;
  font-size: 1.4rem;
  background-color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
}
