.banana-input {
  position: relative;
  display: flex;
  align-items: flex-end; }

.banana-input:after {
  content: '';
  position: absolute;
  bottom: -1px;
  right: 50%;
  height: 2px;
  width: 0;
  background-color: #8bc34a;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 2; }

.banana-input:before {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 50%;
  height: 2px;
  width: 0;
  background-color: #8bc34a;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 2; }

.banana-input--focused:after {
  width: 50%; }

.banana-input--focused:before {
  width: 50%; }

.banana-input input {
  position: relative;
  width: 100%;
  height: 2rem;
  padding: 0.285rem 0.285rem 0.285rem 0;
  font-size: 1.2rem;
  color: #333333;
  border: none;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  background-color: inherit;
  bottom: 0px;
  z-index: 1; }

.banana-input input:disabled {
  color: grey;
  cursor: not-allowed; }

.banana-input input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important; }

.banana-input__text {
  position: absolute;
  bottom: 0.5rem;
  left: 0rem;
  color: rgba(0, 0, 0, 0.26);
  font-size: 1.2rem;
  font-family: 'Rubik', verdana, arial, helvetica, sans-serif;
  z-index: 0;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1); }

.banana-input--text-exists .banana-input__text {
  font-size: 0.9rem;
  color: #8bc34a;
  transform: translate(0, -1.45rem); }

.banana-input--no-top-text {
  height: auto; }

.banana-input__options {
  width: 100%;
  padding: 0.71rem 0;
  max-height: 140px;
  overflow: auto;
  position: absolute;
  bottom: -1px;
  left: 0;
  transform: translate(0%, 100%);
  box-sizing: border-box;
  background-color: #fff;
  z-index: 2;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2); }

.banana-input__options__option {
  display: block;
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  background-color: inherit;
  border: none;
  font-size: 1.2rem;
  text-align: left;
  padding: 0.7rem;
  cursor: pointer;
  box-sizing: border-box; }

.banana-input__options__option.active {
  background-color: #eee; }

.banana-input__options__option:hover {
  background-color: #eee; }

.react-datepicker-popper {
  z-index: 100; }

.react-datepicker__input-container {
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1); }

.react-datepicker__input-container:after {
  content: '';
  position: absolute;
  bottom: -1px;
  right: 50%;
  height: 2px;
  width: 0;
  background-color: #8bc34a;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 2; }

.react-datepicker__input-container:before {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 50%;
  height: 2px;
  width: 0;
  background-color: #8bc34a;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 2; }

.react-datepicker__tether-enabled:after,
.react-datepicker__tether-enabled:before {
  width: 50%; }

.banana-input__text-picker {
  width: 100%; }
