.loading-spinner {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.2);
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(../../../../images/loading-spinner.gif);
  background-size: 50px;
}
