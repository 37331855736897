.shipment-details {
  font-family: 'Helvetica', 'Arial', sans-serif;
  padding: 1.5rem;
  margin-bottom: 2rem;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);

  .shipment-details__title {
    margin-bottom: 3rem;
    font-size: 2rem;
  }

  .shipment_details__content {
    display: flex;
    margin-bottom: 2rem;
    position: relative;
  }

  .shipment-cell {
    position: relative;
    padding: 0 0 0 1rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }

  .shipment-cell__child {
    width: 100%;
    display: flex;
  }

  .shipment-cell__child__text {
    flex-grow: 1;
  }

  .shipment-cell__manipulator--hidden {
    display: none;
  }

  .shipment-cell--width-2 {
    width: 16.6666667%;
  }

  .shipment-cell--width-3 {
    width: 25%;
  }

  .shipment-cell--width-4 {
    width: 33.333333%;
  }

  .shipment-cell__top-text {
    margin-bottom: 1.5rem;
  }

  .shipment-cell__content {
    font-size: 2rem;
    color: rgba(0, 0, 0, 0.54);
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
  }

  .shipment-cell__edit-button {
    margin-left: 0.5rem;
    padding-right: 1rem;
    cursor: pointer;
  }

  .shipment-cell__manipulator {
    margin-top: 1rem;
    width: 100%;
    align-self: flex-end;
  }
}
