.table {
  display: table;
  margin-bottom: 1rem;
  width: 100%;
}

.table--compact {
  width: 50%;
}

.table__body {
  display: table-row-group;
}

.table__header {
  display: table-row;
}

.table__header__content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.table__header-element {
  display: table-cell;
  padding: 1.28rem 1.28rem;
  color: rgba(0, 0, 0, 0.54);
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  font-size: 0.92rem;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.table__header-filter {
  font-size: 1.4rem;
  padding-left: 0.6rem;
  margin-bottom: 0.2rem;
  transform: translate(0, 0.15rem);
  cursor: pointer;
}

.table__header-element--center {
  text-align: center;
}

.table__header-element--center .table__header-element {
  align-items: center;
}

.table__header .table__header-element:first-child {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.table__header .table__header-element:last-child {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.table__element {
  display: table-row;
  transition: background-color 0.1s linear;
}

.table__element:hover {
  background-color: #eee;
}

.table__element__cell {
  display: table-cell;
  padding: 1.28rem 1.28rem;
  vertical-align: middle;
  color: #333333;
  font-family: 'Roboto', sans-serif;
  font-size: 0.92rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.table__element__cell--no-wrap {
  white-space: nowrap;
}

.table-element__cell--numeric {
  text-align: center;
}

.table__element .table__element__cell:first-child {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.table__element .table__element__cell:last-child {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.table--no-header .table__body .table__element:first-child .table__element__cell {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.table__element__actions-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.table__element__button {
  border: none;
  background-color: inherit;
  font-size: 1.4rem;
  color: #000;
  cursor: pointer;
}

.table__element__button:active,
.table__element__button:focus {
  color: #000;
}
