.banana-tip-button {
  display: flex;
  flex-direction: column-reverse;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
}

.banana-tip-button__content {
  width: 4rem;
  height: 4rem;
  color: #fff;
  font-size: 1.5rem;
  border: none;
  background-color: #ff5300;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
}

.banana-tip-button__content:not(:first-child) {
  margin-bottom: 0.5rem;
}

.banana-tip-button__content--minor {
  width: 3.5rem;
  height: 3.5rem;
  border: none;
  cursor: pointer;
}

.banana-tip-button__content--violet {
  background-color: #5a009d;
}
