.all-rentals-element {
  width: 100%;
  margin-bottom: 1rem;
  display: table; }

.all-rentals-element__row {
  display: table-row;
  transition: background-color ease-out 0.2s; }

.all-rentals-element__row:hover {
  background-color: #ececec; }

.all-rentals-element__cell {
  display: table-cell;
  border-bottom: 1px solid #ececec;
  padding: 1rem;
  vertical-align: middle; }

.all-rentals-element__cell--time {
  width: 7rem; }

.all-rentals-element__main-data {
  font-size: 1.1rem;
  margin-bottom: 0.5rem; }

.all-rentals-element__main-data--no-wrap {
  white-space: nowrap; }

.all-rentals-element__minor-data--no-wrap {
  white-space: nowrap; }

.all-rentals-element__minor-data--region {
  margin-top: 0.8rem; }

.all-rentals-element__minor-data {
  color: rgba(0, 0, 0, 0.54); }

.all-rentals-element__icon {
  font-size: 2rem;
  color: rgba(0, 0, 0, 0.54); }

.all-rentals-element__icon--return-type {
  transform: rotate(-45deg); }

.all-rentals-element__text {
  display: inline-block; }

@media (max-width: 767px) {
  .all-rentals-element__row {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
    padding: 10px 0;
    background-color: #eee; }
  .all-rentals-element__cell {
    padding: 0.2rem;
    flex-wrap: wrap;
    box-sizing: border-box; }
  .all-rentals-element__cell--time {
    display: flex;
    width: calc(100% - 60px);
    order: 2;
    padding-top: 0.5rem;
    margin-bottom: 5px; }
  .all-rentals-element__cell--arrow {
    order: 3;
    margin-right: 10px;
    text-align: center;
    width: 40px; }
  .all-rentals-element__cell--return {
    order: 4;
    display: flex;
    padding-top: 0.5rem;
    margin-bottom: 5px;
    width: calc(100% - 60px); }
  .all-rentals-element__cell--user-icon {
    order: 5;
    width: 40px;
    text-align: center;
    margin-right: 10px; }
  .all-rentals-element__cell--user-name {
    order: 6;
    display: flex;
    padding-top: 0.5rem;
    margin-bottom: 5px;
    width: calc(100% - 60px); }
  .all-rentals-element__cell--location-icon {
    order: 7;
    width: 40px;
    margin-right: 10px;
    text-align: center; }
  .all-rentals-element__cell--address {
    order: 8;
    width: calc(100% - 60px);
    padding-top: 0.5rem; }
  .all-rentals-element__cell--status {
    order: 1;
    display: flex;
    justify-content: center;
    width: 40px;
    margin-right: 10px; }
  .all-rentals-element__main-data {
    margin-right: 5px; } }

.all-rentals-toggle-pickup-returns {
  text-align: right;
  padding-right: 20px;
  margin-bottom: 10px;
  width: 100%; }

.all-rentals-toggle-pickup-returns a {
  border-bottom: 1px dotted black;
  cursor: pointer; }

.all-rentals-grid {
  height: 100%;
  display: grid;
  grid-template-columns: 60% auto;
  grid-template-rows: 40px auto;
  grid-template-areas: 'header countries' 'totals countries'; }

.hidden {
  display: none; }

.all-rentals-grid-header .title {
  text-transform: uppercase;
  font-size: 1.5em;
  font-family: 'Rubik', verdana, arial, helvetica, sans-serif; }

.all-rentals-grid-header {
  grid-area: header; }

.all-rentals-grid-totals {
  grid-area: totals;
  font-size: 3em; }

.all-rentals-grid-totals span {
  font-size: 1.5em;
  font-family: 'Rubik', verdana, arial, helvetica, sans-serif; }

.all-rentals-pickups {
  color: #8bc34a; }

.all-rentals-returns {
  color: #e67e22; }

.all-rentals-grid-countries {
  grid-area: countries;
  display: grid;
  grid-template-columns: auto 110px;
  text-align: right; }

.all-rentals-grid-countries .emoji {
  text-align: right;
  font-size: 1.5em; }

.all-rentals-grid-country-totals {
  font-family: 'Rubik', verdana, arial, helvetica, sans-serif;
  text-align: right;
  font-size: 1.7em; }

@media (max-width: 767px) {
  .all-rentals-grid-totals {
    font-size: 2.5em; }
  .all-rentals-grid-country-totals {
    font-size: 1.3em; }
  .all-rentals-grid-countries {
    grid-template-columns: auto 70px; } }
