.banana-input-button {
  font-family: 'Rubik', verdana, arial, helvetica, sans-serif;
  display: flex;
  flex-wrap: wrap;
}

.banana-input-button__input {
  flex: 1;
  align-self: flex-end;
}

.banana-input-button__button {
  font-size: 1.3rem;
  height: 2.9rem;
  transform: translate(0px, -1px);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  background-color: inherit;
  padding: 0.3rem 1rem;
  cursor: pointer;
}

.banana-input-button__error {
  width: 100%;
  margin-top: 0.3rem;
  font-size: 0.8rem;
  color: #d50000;
}
