.banana-filter {
  padding: 1.7rem 0.7rem 1rem 0.7rem;
  margin-bottom: 1rem;
  background-color: #ececec;
  border-width: 2px;
  border-color: #dad5d5;
  border-style: solid;
}

.banana-filter__row {
  display: flex;
  align-items: flex-end;
  margin-bottom: 2rem;
}

.banana-filter .banana-filter__element:not(:last-child) {
  padding-right: 1.5rem;
}

.banana-filter__element--reset {
  background-color: inherit;
  border: none;
  cursor: pointer;
}

.banana-filter__reset-button {
  font-size: 2rem;
}

.banana-filter__element .banana-input-button__button {
  height: 2.6rem;
  width: 7rem;
  align-self: flex-end;
}

.banana-filter__element--input {
  width: 25rem;
}
