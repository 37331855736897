.invoice__table {
  display: table;
  width: 100%;
}

.invoice__header {
  display: table-row;
}

.invoice__header__cell {
  display: table-cell;
  border: none;
  font-size: 1rem;
  font-weight: bold;
  background-color: inherit;
  cursor: pointer;
}

.invoice__header__cell button {
  width: 100%;
  padding: 0.8rem 0.4rem;
  border: none;
  background-color: inherit;
  font-size: 1rem;
  font-weight: bold;
  text-align: left;
  cursor: pointer;
}

.invoice__header__cell button:hover {
  background-color: #ccc;
}

.invoice__row {
  position: relative;
  display: table-row;
}

.invoice__cell {
  display: table-cell;
  padding: 0.4rem 0.3rem;
  vertical-align: middle;
}

.invoice__row:nth-child(odd) .invoice__cell {
  background-color: #ececec;
}

.invoice__button {
  font-size: 1rem;
  border: 0;
  padding: 0;
  background-color: inherit;
  cursor: pointer;
}

.invoice__download-button {
  display: block;
  width: 100%;
  background-color: inherit;
  font-size: 1rem;
  text-align: left;
  color: #69f0ae;
  font-weight: 500;
  border: none;
  padding: 0;
  cursor: pointer;
  text-decoration: underline;
}

.invoice__generate-button {
  margin-top: 1rem;
  padding: 0.7rem;
  font-size: 1.2rem;
  background: rgba(158, 158, 158, 0.2);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
