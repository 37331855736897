.new-mock-form {
  width: 50%;
  margin: auto;
}

.new-mock-form__element-text {
  margin-top: 2rem;
}

.new-mock-form__element {
  margin-top: 1.3rem;
}

.new-mock-form__save-button {
  margin-top: 3rem;
  text-align: right;
}

.new-mock-form__element-text--first {
  margin-top: 4rem;
}
