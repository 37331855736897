.banana-popup {
  display: flex;
  top: 0;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.2); }

.banana-popup__content {
  padding: 2rem 3rem;
  background-color: #fff;
  box-shadow: 0 9px 46px 8px rgba(0, 0, 0, 0.14), 0 11px 15px -7px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.2); }

.banana-popup__title {
  padding: 0rem 0rem 2rem 0rem;
  font-size: 2rem;
  max-width: 40rem;
  font-family: 'Helvetica', 'Arial', sans-serif; }

.banana-popup__actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem; }

.banana-popup__button {
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-size: 1.2rem;
  border: none;
  background-color: inherit;
  padding: 0.5rem;
  min-width: 4rem;
  margin-right: 1rem;
  background-color: #9b9b9b;
  cursor: pointer; }

.banana-popup__button:hover {
  background-color: #ddd; }

.banana-popup__button:focus {
  background-color: #ccc;
  outline: none; }
