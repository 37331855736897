.login-title {
  font-size: 20px;
  font-family: 'Rubik', verdana, arial, helvetica, sans-serif;
  font-weight: bold;
  color: #fff;
  text-align: center;
}

.login-content {
  text-align: center;
}

.login-content__image {
  width: 8rem;
  margin-bottom: 36px;
}

.login-content__input {
  box-shadow: 0px 1px 0px 0px #ffffff;
  width: 24rem;
}

.login-content__input input {
  color: #fff;
}

.login-content__input::after {
  background-color: #ffffff;
}

.login-content__input::before {
  background-color: #ffffff;
}

.login-content__input-box {
  margin-top: 25px;
}

.login-content__input-text {
  color: #fff;
}

.banana-input--text-exists .banana-input__text {
  color: rgba(255, 255, 255, 0.4);
}

.login-content__button {
  margin-top: 20px;
  width: 100%;
  border-radius: 0px;
  border: 1px solid #fff;
  background-color: transparent;
}

.login-content__button:focus {
  border: 1px dashed #fff;
}
